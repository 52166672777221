export * from "./consts";
export * from "./MenuBook";
export * from "./modals";
export * from "./common";
export * from "./utils";

// module declare
declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["primary"];
    }
    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}
declare module "@mui/material/Icon" {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}
declare module "@mui/material/SvgIcon" {
    interface SvgIconPropsColorOverrides {
        neutral: true;
    }
}
