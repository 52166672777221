import { Badge as MuiBadge, SxProps, Typography } from "@mui/material";

import { tmrColorPalette } from "../consts/colors";

// tmrcolorPalette 내부에 있는 number을 다 가져온다
type TmrColorPaletteNumber<T> = keyof T[keyof T];
// 뱃지는 하얀색을 안 쓸 것 같으니 뺀다. 그리고 number가 100 밖에 없으니 더더욱 뺀다.
type TmrColorPalleteOmitWhite = Omit<typeof tmrColorPalette, "white" | "black" | "background">;

type BadgeType = keyof TmrColorPalleteOmitWhite;
type TmrColorPalleteOmitWhiteNumber = TmrColorPaletteNumber<TmrColorPalleteOmitWhite>;

interface BadgeProps {
    size: 1 | 2; // badge 사이즈가 얼마나 추가될지 몰라 일단 숫자로 표시
    title: string;
    badgeType?: BadgeType;
    badgeSX?: SxProps;
    typographySX?: SxProps;
}

export const Badge = ({
    badgeType = "info",
    size,
    title,
    badgeSX,
    typographySX,
}: BadgeProps) => {
    const badgeBackgroundColor = (badgeType: BadgeType) => {
        let colorNumber: TmrColorPalleteOmitWhiteNumber = 100;
        if (badgeType === "positive" || "negative") {
            colorNumber = 100;
        }
        return tmrColorPalette[badgeType][colorNumber];
    };

    const badgeTitleColor = (badgeType: BadgeType) => {
        let colorNumber: TmrColorPalleteOmitWhiteNumber = 500;
        if (badgeType === "positive" || "negative") {
            colorNumber = 600;
        }
        return tmrColorPalette[badgeType][colorNumber];
    };

    return (
        <MuiBadge
            sx={{
                display: "flex",
                padding: "3px 8px",
                backgroundColor: badgeBackgroundColor(badgeType),
                borderRadius: "10px",
                height: "20px",
                ...(size === 2 && {
                    padding: "8px 15px",
                    fontSize: "14px",
                    borderRadius: "100px",
                    height: "33px",
                }),
                ...badgeSX,
            }}
        >
            <Typography
                variant={size === 1 ? "Caption2SemiBold" : "f14R"}
                color={badgeTitleColor(badgeType)}
                sx={{
                    ...typographySX,
                }}
            >
                {title}
            </Typography>
        </MuiBadge>
    );
};

Badge.displayName = "Badge";
