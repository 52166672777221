export const ENV = process.env.NEXT_PUBLIC_ENV;

export const IS_PROD = ENV === "prod";
export const IS_QA = ENV === "qa";
export const IS_DEV = ENV === "dev";

export const IS_LOCAL = !IS_PROD && !IS_QA && !IS_DEV;

export const TAGHERE_INFO_HOST = IS_PROD
    ? "https://tag-here.info"
    : IS_QA
    ? "https://q.tag-here.info"
    : IS_DEV
    ? "https://d.tag-here.info"
    : "http://localhost:4202";
