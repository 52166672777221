import React from "react";

import { Dialog, DialogProps, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import { tmrColorPalette } from "../consts";
import { ForwardedSlideUpTransition } from "../modals";

interface FullScreenModalProps {
    open?: boolean;
    onClose?: () => void;
    forceMobile?: boolean;
    children?: React.ReactNode;
}

const FullScreenModal: React.FC<FullScreenModalProps & DialogProps> = ({
    open,
    onClose,
    children,
    forceMobile,
    ...dialogProps
}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery) || forceMobile;

    return (
        <Dialog
            {...dialogProps}
            open={open}
            onClose={() => {}}
            fullScreen
            scroll={"paper"}
            TransitionComponent={ForwardedSlideUpTransition}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: tmrColorPalette.white[100],
                    },
                },
            }}
            PaperProps={{
                ...dialogProps.PaperProps,
                sx: {
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: 0,
                    width: "100%",
                    maxWidth: "430px",
                    ...(isMobile && {
                        height: "100dvh",
                        "@supports (-webkit-touch-callout: none)": {
                            height: "-webkit-fill-available",
                        },
                    }),
                    ...dialogProps.PaperProps?.sx,
                },
            }}
        >
            {children}
        </Dialog>
    );
};

export default FullScreenModal;
