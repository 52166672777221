import { MenuPieceSkin, MenuPieceType } from "@taghere/interface";

const getDefaultByType = (type: MenuPieceType): MenuPieceSkin => {
    switch (type) {
        case MenuPieceType.IMAGE:
            return {
                label: "이벤트 이미지로 보기",
            };
        case MenuPieceType.SIMPLE_PAYMENT:
            return {
                label: "송금하기",
                descs: ["클릭 한 번으로 간편하게\n송금하기로 결제 하기"],
            };
        case MenuPieceType.COPY_ACCOUNT:
            return {
                label: "계좌번호 복사하기",
                descs: ["터치 한 번으로\n계좌번호 복사하기"],
            };
        case MenuPieceType.COPY_CONTACT:
            return {
                label: "전화번호 복사하기",
                descs: ["터치 한 번으로\n전화번호 복사하기"],
            };
        case MenuPieceType.LINK:
            return {
                label: "바로가기",
                descs: ["링크로 바로가기"],
            };
        default:
            return {};
    }
};
export default getDefaultByType;
