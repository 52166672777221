import React, { useState } from "react";
import ImageViewer from "./ImageViewer";

const useImageViewer = (
    fitToWidth = false
): [React.Dispatch<React.SetStateAction<string | undefined>>, React.FC] => {
    const [src, setSrc] = useState<string | undefined>(undefined);
    const onClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        setSrc(undefined);
    };
    const ImageViewerWrapper: React.FC = () => {
        return (
            <ImageViewer src={src} onClick={onClick} fitToWidth={fitToWidth} />
        );
    };
    return [setSrc, ImageViewerWrapper];
};

export default useImageViewer;
