"use client";

import React, { useEffect, useState } from "react";

import { usePathname, useSearchParams, useSelectedLayoutSegment } from "next/navigation";

import {
    LANDING_BUTTON_LINK,
    LANDING_BUTTON_LINK_BIZCONNECT,
    LANDING_BUTTON_LINK_META,
    LANDING_BUTTON_LINK_PAYMENT,
    LANDING_BUTTON_LINK_SUJONG,
} from "@/consts/url";

const bizconnect = "bizconnect";
const sujong = "sujong";
const meta = "meta";

export const useTallyLink = () => {
    const search = useSearchParams();
    const [tallyLink, setTallyLink] = useState(LANDING_BUTTON_LINK);
    const path = usePathname();

    useEffect(() => {
        if (path.includes("payment")) {
            setTallyLink(() => LANDING_BUTTON_LINK_PAYMENT);
        } else if (search.toString().includes(bizconnect)) {
            setTallyLink(() => LANDING_BUTTON_LINK_BIZCONNECT);
        } else if (search.toString().includes(sujong)) {
            setTallyLink(() => LANDING_BUTTON_LINK_SUJONG);
        } else if (search.toString().includes(meta)) {
            setTallyLink(() => LANDING_BUTTON_LINK_META);
        }
    }, [search, path]);

    return { tallyLink: tallyLink };
};
