import { ArrowBackRounded, TuneRounded } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import { todayDayJS } from "@taghere/common-dep-utils";
import React, { useEffect, useState } from "react";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BasicModal } from "../common";

export interface DateFilterModalProps {
    /**
     * 날짜 바뀔때
     * @param date - YYYY-MM-DD
     * @returns
     */
    onChange?: (date: string) => void;
    /**
     * 확인 버튼 콜백. undefined 면 확인버튼도 없음
     * @param date - YYYY-MM-DD
     * @returns
     */
    onConfirm?: (date: string) => void;
}

const DateFilterModal: React.FC<DateFilterModalProps> = ({
    onChange,
    onConfirm,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [targetDate, setTargetDate] = useState(() => todayDayJS());
    const handleClose = () => {
        setIsOpen(() => false);
    };

    const handleConfirm = () => {
        const value = targetDate.format("YYYY-MM-DD");
        if (onConfirm) onConfirm(value);
        handleClose();
    };

    useEffect(() => {
        // 만약 confirm 이 지정안되면 날짜가 바뀔 때마다 꺼짐
        if (targetDate) {
            if (onChange) onChange(targetDate.format("YYYY-MM-DD"));
            if (!handleConfirm) handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDate]);

    return (
        <>
            <IconButton size="small" onClick={() => setIsOpen(() => true)}>
                <TuneRounded />
            </IconButton>
            <BasicModal open={isOpen} onClose={handleClose} title={"날짜선택"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                        format="YYYY/MM/DD"
                        value={targetDate}
                        onChange={(newVal) => {
                            if (newVal) {
                                setTargetDate(() => newVal);
                                handleClose();
                            }
                        }}
                    />
                </LocalizationProvider>

                {onConfirm && (
                    <DialogActions>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleConfirm}
                        >
                            적용
                        </Button>
                    </DialogActions>
                )}
            </BasicModal>
        </>
    );
};

export default DateFilterModal;
