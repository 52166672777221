import toast from "react-hot-toast";

// SECTION 토스트코어

/**
 * NOTE: 본래 메소드가 lowercase 이므로 lowercase로 구분
 */
export enum ToastHandlerType {
    error = "error",
    success = "success",
    loading = "loading",
    custom = "custom",
}

// SECTION 장바구니

export enum ShoppingCartToastState {
    ADD = "added",
    DEL = "deleted",
    ORDER = "ordered",
    CLEAR = "clear", // 예전 error
    CALL = "called", // 호출
    ERROR = "error", // 소켓 에러
}

export type ShoppingCartToastStateExtended = ShoppingCartToastState | "";

export type ShoppingCartToastCallbackMap = {
    [key in ShoppingCartToastState]?: () => void;
};

export type ShoppingCartToastMessageMap = {
    [key in ShoppingCartToastState]: string;
};

export type ShoppingCartToastHandlerMap = {
    [key in ShoppingCartToastState]: ToastHandlerType;
};

export interface ShoppingCartToastCallbackMapExtended extends ShoppingCartToastCallbackMap {
    common?: () => void;
}

// SECTION SNS 공유 관련
// NOTE: 실제로 사용 안할것처럼 보임

export enum ShareToSNSState {
    COPY = "copied",
}

export type ShareToSNSCallbackMap = {
    [key in ShareToSNSState]?: () => void;
};

export type ShareToSNSMessageMap = {
    [key in ShareToSNSState]: string;
};

export type ShareToSNSHandlerMap = {
    [key in ShareToSNSState]: ToastHandlerType;
};

export const shareToSNSMessages: ShareToSNSMessageMap = {
    copied: "클립보드에 복사되었습니다.",
};

export const shareToSNSHandlers: ShareToSNSHandlerMap = {
    copied: ToastHandlerType.success,
};

export const showShareToSNSToast = (
    toastState: ShareToSNSState,
    callbacks?: ShareToSNSCallbackMap,
) => {
    const method = toast[shareToSNSHandlers[toastState]];
    const message = shareToSNSMessages[toastState];
    method(message);

    if (callbacks) {
        const callback = callbacks[toastState];
        if (callback) {
            callback();
        }
    }
};

export const shoppingCartToastHandlers: ShoppingCartToastHandlerMap = {
    added: ToastHandlerType.success,
    deleted: ToastHandlerType.error,
    ordered: ToastHandlerType.success,
    clear: ToastHandlerType.error,
    called: ToastHandlerType.success,
    error: ToastHandlerType.error,
};

// ADMIN TOAST

export enum MenuEditToastState {
    WRONG_ACC = "access_denied",
    DURING_GEN = "during_generation",
    NOT_FOUND = "not_found",
    UPDATE_FAILED = "update_failed",
    IMAGE_UPLOAD_FAILED = "image_upload_failed",
    UNSTABLE_CONN = "unstable_connection",
    REQUIRED_IMG = "required_image",
    EMPTY_FIELD = "empty_field",
    EMPTY_LOGIN = "empty_login",
    UPDATE_SUCCESS = "update_success",
    DELETE_SUCCESS = "delete_success",
    ADD_SUCCESS = "add_success",
    SAVE_SUCCESS = "save_success",
}

export type MenuEditToastStateExtended = MenuEditToastState | "";

export type MenuEditToastCallbackMap = {
    [key in MenuEditToastState]?: () => void;
};

export type MenuEditToastMessageMap = {
    [key in MenuEditToastState]: string;
};

export type MenuEditToastHandlerMap = {
    [key in MenuEditToastState]?: ToastHandlerType;
};

export interface MenuEditToastCallbackMapExtended extends MenuEditToastCallbackMap {
    common?: () => void;
}

export const menuEditToastMessages: MenuEditToastMessageMap = {
    access_denied: "잘못된 접근입니다.",
    not_found: "존재하지 않는 회원이거나 비밀번호를 잘못 입력하였습니다.",
    during_generation: "가게 생성 중에 있습니다 :) 이 메시지가 지속될 시 문의주세요.",
    update_failed: "메뉴 업데이트에 실패하였습니다.",
    image_upload_failed: "이미지 추가에 실패했습니다. 메뉴를 다시 만들어주세요",
    unstable_connection: "오류가 발생했습니다. 인터넷 연결상태를 확인해주세요.",
    required_image: "이미지를 반드시 추가해주세요.",
    empty_field: "내용을 입력해주세요.",
    empty_login: "아이디/비밀번호를 입력해주세요.",
    update_success: "수정되었습니다.",
    delete_success: "삭제되었습니다.",
    add_success: "추가되었습니다.",
    save_success: "저장되었습니다.",
};

export const menuEditToastHandlers: MenuEditToastHandlerMap = {
    update_success: ToastHandlerType.success,
    delete_success: ToastHandlerType.success,
    add_success: ToastHandlerType.success,
    save_success: ToastHandlerType.success,
};

export const showMenuEditToast = (
    toastState: MenuEditToastState,
    callbacks?: MenuEditToastCallbackMapExtended,
) => {
    const method = toast[menuEditToastHandlers[toastState] ?? "error"];
    const message = menuEditToastMessages[toastState];
    method(message);

    if (callbacks) {
        const callback = callbacks[toastState];
        if (callback) {
            callback();
        }
    }

    if (callbacks?.common) {
        callbacks.common();
    }
};
