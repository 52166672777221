import { useRef } from "react";

export const useThrottle = <T extends (...args: Parameters<T>) => unknown>(
    callback: T,
    delay: number
) => {
    const isWaiting = useRef(false);

    return (...args: Parameters<T>) => {
        if (isWaiting.current) return;

        callback(...args);
        isWaiting.current = true;

        setTimeout(() => {
            isWaiting.current = false;
        }, delay);
    };
};
