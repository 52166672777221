import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import { FontWeightValues } from "@taghere/common-utils";

declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["primary"];
    }
    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
    }
    interface TypographyVariants {
        // callconfig
        f12M: React.CSSProperties;
        f12B: React.CSSProperties;
        f14R: React.CSSProperties;
        f14M: React.CSSProperties;
        f14SB: React.CSSProperties;
        f14B: React.CSSProperties;
        f14EB: React.CSSProperties;
        f15SB: React.CSSProperties;
        f15M: React.CSSProperties;
        f15B: React.CSSProperties;
        f16R: React.CSSProperties;
        f16M: React.CSSProperties;
        f16SB: React.CSSProperties;
        f16B: React.CSSProperties;
        f17B: React.CSSProperties;
        f18M: React.CSSProperties;
        f18B: React.CSSProperties;
        f20R: React.CSSProperties;
        f20M: React.CSSProperties;
        f20SB: React.CSSProperties;
        f20B: React.CSSProperties;
        f20EB: React.CSSProperties;
        f22M: React.CSSProperties;
        f22B: React.CSSProperties;
        f24R: React.CSSProperties;
        f24M: React.CSSProperties;
        f24B: React.CSSProperties;
        f26B: React.CSSProperties;
        f28M: React.CSSProperties;
        f28SB: React.CSSProperties;
        f28B: React.CSSProperties;
        f30M: React.CSSProperties;
        f30B: React.CSSProperties;
        f32M: React.CSSProperties;
        f32B: React.CSSProperties;
        f36SB: React.CSSProperties;
        f36B: React.CSSProperties;
        f36EB: React.CSSProperties;
        f40B?: React.CSSProperties;
        f42B: React.CSSProperties;
        f48B: React.CSSProperties;
        f56B: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        f12M?: React.CSSProperties;
        f12B?: React.CSSProperties;
        f14R?: React.CSSProperties;
        f14M?: React.CSSProperties;
        f14SB?: React.CSSProperties;
        f14B?: React.CSSProperties;
        f14EB?: React.CSSProperties;
        f15SB?: React.CSSProperties;
        f15M?: React.CSSProperties;
        f15B?: React.CSSProperties;
        f16R?: React.CSSProperties;
        f16M?: React.CSSProperties;
        f16SB?: React.CSSProperties;
        f16B?: React.CSSProperties;
        f17B?: React.CSSProperties;
        f18M?: React.CSSProperties;
        f18B?: React.CSSProperties;
        f20R?: React.CSSProperties;
        f20M?: React.CSSProperties;
        f20SB?: React.CSSProperties;
        f20B?: React.CSSProperties;
        f20EB?: React.CSSProperties;
        f22M?: React.CSSProperties;
        f22B?: React.CSSProperties;
        f24R?: React.CSSProperties;
        f24M?: React.CSSProperties;
        f24B?: React.CSSProperties;
        f26B?: React.CSSProperties;
        f28M?: React.CSSProperties;
        f28SB?: React.CSSProperties;
        f28B?: React.CSSProperties;
        f30M?: React.CSSProperties;
        f30B?: React.CSSProperties;
        f32M?: React.CSSProperties;
        f32B?: React.CSSProperties;
        f36SB?: React.CSSProperties;
        f36B?: React.CSSProperties;
        f36EB?: React.CSSProperties;
        f40B?: React.CSSProperties;
        f42B?: React.CSSProperties;
        f48B?: React.CSSProperties;
        f56B?: React.CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        f12M: true;
        f12B: true;
        f14R: true;
        f14M: true;
        f14SB: true;
        f14B: true;
        f14EB: true;
        f15SB: true;
        f15M: true;
        f15B: true;
        f16R: true;
        f16M: true;
        f16SB: true;
        f16B: true;
        f17B: true;
        f18M: true;
        f18B: true;
        f20R: true;
        f20M: true;
        f20SB: true;
        f20B: true;
        f20EB: true;
        f22M: true;
        f22B: true;
        f24R: true;
        f24M: true;
        f24B: true;
        f26B: true;
        f28M: true;
        f28SB: true;
        f28B: true;
        f30M: true;
        f30B: true;
        f32M: true;
        f32B: true;
        f36SB: true;
        f36B: true;
        f36EB: true;
        f40B: true;
        f42B: true;
        f48B: true;
        f56B: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}
declare module "@mui/material/Icon" {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}
declare module "@mui/material/SvgIcon" {
    interface SvgIconPropsColorOverrides {
        neutral: true;
    }
}

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: "#F9BD38",
        },
        secondary: {
            main: "#1ECCA2",
        },
        error: {
            main: red[400],
        },
        neutral: {
            main: "#333",
            contrastText: "#fff",
        },
        text: {
            primary: "#333",
            secondary: "#666",
            disabled: "#BBB",
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {},
            },
            defaultProps: {
                inputProps: {
                    sx: {
                        bgcolor: "#fff",
                        borderRadius: 1,
                        py: 1.3,
                        fontSize: 14,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 14,
                    fontWeight: FontWeightValues.SEMI_BOLD,
                    // color: "#FFF",
                    height: 40,
                    minWidth: 0,
                    ":hover": {
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                inputProps: { sx: { py: 1.2, fontSize: 14 } },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: FontWeightValues.REGULAR,
                },
            },
        },
    },
    typography: {
        fontFamily: "Pretendard JP",

        f12M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "12px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f12B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "12px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f14R: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "14px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f14M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "14px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f14SB: {
            fontWeight: FontWeightValues.SEMI_BOLD,
            fontSize: "14px",
            letterSpacing: "-0.03em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f14B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "14px",
            letterSpacing: "-0.03em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f14EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "14px",
            letterSpacing: "-0.03em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f15SB: {
            fontWeight: FontWeightValues.SEMI_BOLD,
            fontSize: "15px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f15M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "15px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f15B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "15px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f16R: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "16px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f16M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "16px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f16SB: {
            fontWeight: FontWeightValues.SEMI_BOLD,
            fontSize: "16px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f16B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "16px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f17B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "17px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f18M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "18px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f18B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "18px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f20R: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "20px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f20M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "20px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f20SB: {
            fontWeight: FontWeightValues.SEMI_BOLD,
            fontSize: "20px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f20B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "20px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f20EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "20px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f22M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "22px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.5,
        },
        f22B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "22px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.5,
        },
        f24R: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "24px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f24M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "24px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f24B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "24px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f26B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "26px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f28M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "28px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f28SB: {
            fontWeight: FontWeightValues.SEMI_BOLD,
            fontSize: "28px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f28B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "28px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f30M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "30px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f30B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "30px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f32M: {
            fontWeight: FontWeightValues.MEDIUM,
            fontSize: "32px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f32B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "32px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f36SB: {
            fontWeight: FontWeightValues.SEMI_BOLD,
            fontSize: "36px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f36B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "36px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f36EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "36px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f40B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "40px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f42B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "42px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f48B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "48px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
        f56B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "56px",
            letterSpacing: "-0.04em",
            wordBreak: "keep-all",
            lineHeight: 1.3,
        },
    },
});

export default theme;
