import React, { ReactNode } from "react";

import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

import { FontWeightValues } from "@taghere/common-utils";

import theme from "./theme";

const externalMenuTheme = createTheme({
    ...theme,
    // typography: {
    //     fontFamily: "Pretendard Variable",

    //     f12M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "12px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f12B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "12px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f14R: {
    //         fontWeight: FontWeightValues.REGULAR,
    //         fontSize: "14px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f14M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "14px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f14SB: {
    //         fontWeight: FontWeightValues.SEMI_BOLD,
    //         fontSize: "14px",
    //         letterSpacing: "-0.03em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f14B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "14px",
    //         letterSpacing: "-0.03em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f14EB: {
    //         fontWeight: FontWeightValues.EXTRA_BOLD,
    //         fontSize: "14px",
    //         letterSpacing: "-0.03em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f15SB: {
    //         fontWeight: FontWeightValues.SEMI_BOLD,
    //         fontSize: "15px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f15M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "15px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f15B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "15px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f16R: {
    //         fontWeight: FontWeightValues.REGULAR,
    //         fontSize: "16px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f16M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "16px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f16B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "16px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f18B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "18px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f20M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "20px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f20SB: {
    //         fontWeight: FontWeightValues.SEMI_BOLD,
    //         fontSize: "20px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f20B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "20px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f20EB: {
    //         fontWeight: FontWeightValues.EXTRA_BOLD,
    //         fontSize: "20px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f24R: {
    //         fontWeight: FontWeightValues.REGULAR,
    //         fontSize: "24px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f24B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "24px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f26B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "26px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f28SB: {
    //         fontWeight: FontWeightValues.SEMI_BOLD,
    //         fontSize: "28px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f28B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "28px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f30M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "30px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f30B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "30px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f32M: {
    //         fontWeight: FontWeightValues.MEDIUM,
    //         fontSize: "32px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f32B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "32px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f36SB: {
    //         fontWeight: FontWeightValues.SEMI_BOLD,
    //         fontSize: "36px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f36B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "36px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f36EB: {
    //         fontWeight: FontWeightValues.EXTRA_BOLD,
    //         fontSize: "36px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f40B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "40px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f42B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "42px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f48B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "48px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    //     f56B: {
    //         fontWeight: FontWeightValues.BOLD,
    //         fontSize: "56px",
    //         letterSpacing: "-0.04em",
    //         wordBreak: "keep-all",
    //         lineHeight: 1.5,
    //     },
    // },
    spacing: 5,
});

interface LandingPageThemeProviderProps {
    children: ReactNode;
}

const LandingPageThemeProvider = ({ children }: LandingPageThemeProviderProps) => {
    return (
        <ThemeProvider theme={externalMenuTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default LandingPageThemeProvider;
