import React from "react";

import { Grid, Typography } from "@mui/material";

import { ShareToSNSState, showShareToSNSToast } from "@taghere/common-dep-utils";

import copyIcon from "../assets/icons/copy.svg";
import linkIcon from "../assets/icons/link.svg";

export interface CopyLinkChipProps {
    /**
     * 바로가기 chip 클릭시 이동
     */
    link?: string;
    /**
     * 복사하기 chip 클릭시 클립보드에 복사
     */
    copyingText?: string;
}

export const CopyLinkChip: React.FC<CopyLinkChipProps> = ({ link, copyingText }) => {
    return (
        <Grid
            container
            sx={{
                // flex: 1,
                height: "fit-content",
                width: "fit-content",
                borderRadius: 10,
                alignItems: "center",
                justifyContent: "center",
                border: "solid 1px #D2F5EC",
                px: 1,
                flexWrap: "nowrap",

                transition: "all ease-in-out 0.1s",
                cursor: "pointer",
                ":hover": {
                    bgcolor: "#D2F5EC",
                    border: "solid 1px #1ECCA2",
                },
            }}
            onClick={
                link
                    ? () => window.location.assign(link)
                    : copyingText
                    ? (e) => {
                          e.stopPropagation();
                          window.navigator.clipboard
                              .writeText(copyingText)
                              .then(() => showShareToSNSToast(ShareToSNSState.COPY));
                      }
                    : undefined
            }
        >
            <img
                src={link ? linkIcon : copyingText ? copyIcon : ""}
                alt={link ? "바로가기" : copyingText ? "복사하기" : ""}
                style={{
                    height: 10,
                    // flex: 1,
                }}
            />
            <Typography
                sx={{
                    pl: 0.5,
                    fontSize: 10,
                    fontWeight: 800,
                    color: "#1ECCA2",
                    whiteSpace: "nowrap",
                }}
            >
                {link ? "바로가기" : copyingText ? "복사하기" : ""}
            </Typography>
        </Grid>
    );
};

export default CopyLinkChip;
