import React from "react";

import Grid from "@mui/material/Grid";
import useImageViewer from "./useImageViewer";
import SquareMagnifiableImageProps from "./SquareMagnifiableImageProps";

const SquareMagnifiableImage: React.FC<SquareMagnifiableImageProps> = ({
    src,
    size = window.screen.width - 48,
    style,
    isAnonymous = false,
    isContained = true,
    isMagnifiedImageFitToWidth,
    isMobile = false,
}) => {
    const [setSrc, ImageViewer] = useImageViewer(isMagnifiedImageFitToWidth);

    return (
        <>
            <Grid width={isMobile ? `${size}` : "100%"}>
                <img
                    crossOrigin={isAnonymous ? "anonymous" : undefined}
                    src={src}
                    alt={`img`}
                    width={isMobile ? `${size}` : "100%"}
                    // height={`${size}px`}
                    style={{
                        borderRadius: "2px",
                        cursor: "pointer",
                        fontSize: 10,
                        objectFit: isContained ? "scale-down" : "cover",
                        ...style,
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSrc(src);
                    }}
                />
            </Grid>

            <ImageViewer />
        </>
    );
};

export default SquareMagnifiableImage;
