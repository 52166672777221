export const width330 = 330;
export const width370 = 370;
export const fourHundWidth = 400;
export const mobileWidth = 430;
export const nineHundWidth = 900;
export const tenHundWidth = 1000;
export const tabletWidth = 1200;
export const sixHundWidth = 600;

export const width330MaxWidthMediaQuery = `@media (max-width:${width330}px)`;
export const fourHundMaxWidthMediaQuery = `@media (max-width:${fourHundWidth}px)`;
export const mobileMaxWidthMediaQuery = `@media (max-width:${mobileWidth}px)`;
export const nineHundMaxWidthMediaQuery = `@media (max-width:${nineHundWidth}px)`;
export const tenHundMaxWidthMediaQuery = `@media (max-width:${tenHundWidth}px)`;
export const tabletMaxWidthMediaQuery = `@media (max-width:${tabletWidth}px)`;
export const sixHundMaxWidthMediaQuery = `@media (max-width:${sixHundWidth}px)`;
export const threeHundSevenMaxWidthMediaQuery = `@media (max-width:${width370}px)`;

export const posWidth1024 = 1024;
export const posWidth1440 = 1440;
export const posWidth1920 = 1920;
export const posSmall = `@media (max-width:${posWidth1024}px)`;
export const posMedium = `@media (max-width:${posWidth1440}px)`;
/**
 * // TODO
 * 사용하지 않는 변수 제거 요망
 */
export const posLarge = `@media (max-width:${posWidth1920}px)`;
