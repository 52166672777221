import React from "react";
import { Box, Typography } from "@mui/material";
import DescriptionBoxProps from "./DescriptionBoxProps";

const DescriptionBox: React.FC<DescriptionBoxProps> = ({
    text,
    greyBackground,
}) => {
    return (
        <Box
            height={"100%"}
            width={"100%"}
            sx={
                greyBackground
                    ? {
                          backgroundColor: "#F9F9F9",
                          borderRadius: 3,
                          p: 2,
                      }
                    : undefined
            }
        >
            {text.split("\n").map((line, idx) => {
                return (
                    <Typography
                        key={`${line}-${idx}`}
                        sx={{ textAlign: "flex-start", lineHeight: 1.7 }}
                    >
                        {line}
                    </Typography>
                );
            })}
        </Box>
    );
};

export default DescriptionBox;
