import React from "react";

export const useDebounce = <T extends (...args: Parameters<T>) => unknown>(
    callback: T,
    delay: number
) => {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: Parameters<T>) => {
        let result: any;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            result = callback(...args);
        }, delay);
        return result;
    };
};
