import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/ko";
dayjs.extend(utc);
dayjs.extend(timezone);

export const today = () => dayjs().tz("Asia/Seoul").format("YYYY-MM-DD");
export const dateConvertToHangulFormat = (format: string) =>
    dayjs(format).format("YYYY년 MM월 DD일");
export const todayDayJS = () => dayjs().tz("Asia/Seoul");

export const aWeekAgo = () => dayjs().tz("Asia/Seoul").subtract(1, "week").format("YYYY-MM-DD");

export const dateConvertToHangulFormatWithday = (format: string) => {
    const yyyy년mm월dd일format = dayjs(format).format("YYYY년 MM월 DD일");
    const day: unknown = dayjs(format).locale("ko").format("ddd");
    return `${yyyy년mm월dd일format}(${day})`;
};

export const formatAHHmmInKorea = (date?: string | dayjs.Dayjs) => {
    const dateObj = dayjs(date).locale("ko");
    const dayjsformat = dateObj.format("A HH:mm");
    if (dayjsformat.includes("AM")) return dayjsformat.replace("AM", "오전");
    return dayjsformat.replace("PM", "오후");
};
