import { PropsWithChildren, forwardRef } from "react";
import { Box, BoxProps } from "@mui/material";

export const CategoryTabScrollableContainer = forwardRef<
    HTMLDivElement,
    PropsWithChildren<BoxProps>
>(({ children, ...BoxProps }, ref) => {
    return (
        <Box
            {...BoxProps}
            sx={{
                display: "flex",
                pt: 0,
                // columnGap: 1,
                overflowX: "auto",
                maxWidth: "100vw",
                ...BoxProps.sx,
            }}
            ref={ref}
        >
            {children}
        </Box>
    );
});

CategoryTabScrollableContainer.displayName = "CategoryTabScrollableContainer";
