import { Box, useTheme } from "@mui/material";

interface SpacingProps {
    size?: number;
    horizontal?: boolean;
    toPx?: string;
}

const Spacing = ({ size = 0, horizontal = false, toPx }: SpacingProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                flex: "none",
                ...(horizontal
                    ? { width: toPx || theme.spacing(size) }
                    : { height: toPx || theme.spacing(size) }),
            }}
        />
    );
};

export default Spacing;
