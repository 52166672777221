import { IS_DEV, IS_PROD, IS_QA } from "./env";

const TALLY_LINK = "https://tally.so/r";
const TALLY_TAGHERE_SUB_DOMAIN_LINK = "https://forms.tag-here.com";
export const LANDING_BUTTON_LINK = `${TALLY_TAGHERE_SUB_DOMAIN_LINK}/w4BxDo`;
export const LANDING_BUTTON_LINK_BIZCONNECT = `${TALLY_LINK}/m6kdQk`;
export const LANDING_BUTTON_LINK_SUJONG = `${TALLY_TAGHERE_SUB_DOMAIN_LINK}/meE62Q`;
export const LANDING_BUTTON_LINK_META = `${TALLY_TAGHERE_SUB_DOMAIN_LINK}/mJRkkz`;
export const LANDING_BUTTON_LINK_PAYMENT = `${TALLY_LINK}/mOYRbY`;

export const META_TALLY_LINK = `${TALLY_LINK}/nrBAbL`;
export const META_COMP_TALLY_LINK = `${TALLY_LINK}/3jbQq9`;
// const LANDING_BUTTON_LINK = "http://pf.kakao.com/_WDxiyxj/chat";

export const TAGHERE_INFO_HOST = IS_PROD
    ? "https://tag-here.info"
    : IS_QA
    ? "https://q.tag-here.info"
    : IS_DEV
    ? "https://d.tag-here.info"
    : "http://localhost:4202";

export const TAGHERE_FRONT_HOST = IS_PROD
    ? "https://tag-here.com"
    : IS_QA
    ? "https://front.q.tag-here.com"
    : IS_DEV
    ? "https://front.d.tag-here.com"
    : "http://localhost:4200";

export const TAGHERE_ADMIN_HOST = IS_PROD
    ? "https://admin.tag-here.com"
    : IS_QA
    ? "https://admin.q.tag-here.com"
    : IS_DEV
    ? "https://admin.d.tag-here.com"
    : "http://localhost:4201";

export const CHANNAL_TALK_LINK = "https://tag-here.channel.io";
