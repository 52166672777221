import { FontWeightValues } from "@taghere/common-utils";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        // callconfig
        f10M: React.CSSProperties;
        f10B: React.CSSProperties;
        f10EB: React.CSSProperties;
        f11M: React.CSSProperties;
        f12R: React.CSSProperties;
        f12M: React.CSSProperties;
        f12B: React.CSSProperties;
        f13M: React.CSSProperties;
        f14R: React.CSSProperties;
        f14M: React.CSSProperties;
        f14SB: React.CSSProperties;
        f14B: React.CSSProperties;
        f14EB: React.CSSProperties;
        f15SB: React.CSSProperties;
        f15M: React.CSSProperties;
        f15B: React.CSSProperties;
        f16R: React.CSSProperties;
        f16M: React.CSSProperties;
        f16SB: React.CSSProperties;
        f16B: React.CSSProperties;
        f16EB: React.CSSProperties;
        f18B: React.CSSProperties;
        f20M: React.CSSProperties;
        f20SB: React.CSSProperties;
        f20B: React.CSSProperties;
        f20EB: React.CSSProperties;
        f22B: React.CSSProperties;
        f24R: React.CSSProperties;
        f24B: React.CSSProperties;
        f26B: React.CSSProperties;
        f28SB: React.CSSProperties;
        f28B: React.CSSProperties;
        f30M: React.CSSProperties;
        f30B: React.CSSProperties;
        f32M: React.CSSProperties;
        f32B: React.CSSProperties;
        f34EB: React.CSSProperties;
        f36SB: React.CSSProperties;
        f36B: React.CSSProperties;
        f36EB: React.CSSProperties;
        f40B?: React.CSSProperties;
        f42B: React.CSSProperties;
        f48B: React.CSSProperties;
        f56B: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        f10M?: React.CSSProperties;
        f10B?: React.CSSProperties;
        f10EB?: React.CSSProperties;
        f11M?: React.CSSProperties;
        f12R?: React.CSSProperties;
        f12M?: React.CSSProperties;
        f12B?: React.CSSProperties;
        f13M?: React.CSSProperties;
        f14R?: React.CSSProperties;
        f14M?: React.CSSProperties;
        f14SB?: React.CSSProperties;
        f14B?: React.CSSProperties;
        f14EB?: React.CSSProperties;
        f15SB?: React.CSSProperties;
        f15M?: React.CSSProperties;
        f15B?: React.CSSProperties;
        f16R?: React.CSSProperties;
        f16M?: React.CSSProperties;
        f16SB?: React.CSSProperties;
        f16B?: React.CSSProperties;
        f16EB?: React.CSSProperties;
        f18M?: React.CSSProperties;
        f18SB?: React.CSSProperties;
        f18B?: React.CSSProperties;
        f20M?: React.CSSProperties;
        f20SB?: React.CSSProperties;
        f20B?: React.CSSProperties;
        f20EB?: React.CSSProperties;
        f22B?: React.CSSProperties;
        f24R?: React.CSSProperties;
        f24B?: React.CSSProperties;
        f26B?: React.CSSProperties;
        f28SB?: React.CSSProperties;
        f28B?: React.CSSProperties;
        f30M?: React.CSSProperties;
        f30B?: React.CSSProperties;
        f32M?: React.CSSProperties;
        f32B?: React.CSSProperties;
        f34EB?: React.CSSProperties;
        f36SB?: React.CSSProperties;
        f36B?: React.CSSProperties;
        f36EB?: React.CSSProperties;
        f40B?: React.CSSProperties;
        f42B?: React.CSSProperties;
        f48B?: React.CSSProperties;
        f56B?: React.CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        f10M: true;
        f10B: true;
        f10EB: true;
        f11M: true;
        f12R: true;
        f12M: true;
        f12B: true;
        f13M: true;
        f14R: true;
        f14M: true;
        f14SB: true;
        f14B: true;
        f14EB: true;
        f15SB: true;
        f15M: true;
        f15B: true;
        f16R: true;
        f16M: true;
        f16SB: true;
        f16B: true;
        f16EB: true;
        f18M: true;
        f18SB: true;
        f18B: true;
        f20M: true;
        f20SB: true;
        f20B: true;
        f20EB: true;
        f22B: true;

        f24R: true;
        f24B: true;
        f26B: true;
        f28SB: true;
        f28B: true;
        f30M: true;
        f30B: true;
        f32M: true;
        f32B: true;
        f34EB: true;
        f36SB: true;
        f36B: true;
        f36EB: true;
        f40B: true;
        f42B: true;
        f48B: true;
        f56B: true;
    }
}

export const typographyToken = {
    fontFamily: "Pretendard Variable",
    f10M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "10px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f10B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "10px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f10EB: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "10px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f11M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "11px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f12R: {
        fontWeight: FontWeightValues.REGULAR,
        fontSize: "12px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },

    f12M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "12px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f12B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "12px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f13M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "13px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f14R: {
        fontWeight: FontWeightValues.REGULAR,
        fontSize: "14px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f14M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "14px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f14SB: {
        fontWeight: FontWeightValues.SEMI_BOLD,
        fontSize: "14px",
        letterSpacing: "-0.03em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f14B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "14px",
        letterSpacing: "-0.03em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f14EB: {
        fontWeight: FontWeightValues.EXTRA_BOLD,
        fontSize: "14px",
        letterSpacing: "-0.03em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f16M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "16px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },

    f16SB: {
        fontWeight: FontWeightValues.SEMI_BOLD,
        fontSize: "16px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f16B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "16px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f16EB: {
        fontWeight: FontWeightValues.EXTRA_BOLD,
        fontSize: "16px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f18M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "18px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f18SB: {
        fontWeight: FontWeightValues.SEMI_BOLD,
        fontSize: "18px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },

    f18B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "18px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f20SB: {
        fontWeight: FontWeightValues.SEMI_BOLD,
        fontSize: "20px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f20B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "20px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f20EB: {
        fontWeight: FontWeightValues.EXTRA_BOLD,
        fontSize: "20px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f22B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "22px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f24B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "24px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f30M: {
        fontWeight: FontWeightValues.MEDIUM,
        fontSize: "30px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f30B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "30px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f34EB: {
        fontWeight: FontWeightValues.EXTRA_BOLD,
        fontSize: "34px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f36SB: {
        fontWeight: FontWeightValues.SEMI_BOLD,
        fontSize: "36px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f36B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "36px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },

    f36EB: {
        fontWeight: FontWeightValues.EXTRA_BOLD,
        fontSize: "36px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
    f56B: {
        fontWeight: FontWeightValues.BOLD,
        fontSize: "56px",
        letterSpacing: "-0.04em",
        wordBreak: "keep-all",
        lineHeight: 1.3,
    },
} as const;
