export const tmrColorPalette = {
    primary: {
        900: "#7A580C",
        800: "#936F14",
        700: "#B78F20",
        600: "#DBB12F",
        500: "#FFD541",
        400: "#FFE270",
        300: "#FFEB8D",
        200: "#FFF3B3",
        100: "#FFFAD9",
    },
    secondary: {
        900: "#131651",
        800: "#1F2462",
        700: "#31377A",
        600: "#484E92",
        500: "#636AAA",
        400: "#8C93CC",
        300: "#ABB2E5",
        200: "#CDD2F6",
        100: "#E5E8FA",
    },
    grey: {
        900: "#030404",
        800: "#1D1F22",
        700: "#383B40",
        600: "#55585E",
        500: "#73767C",
        400: "#91949A",
        300: "#B1B3B8",
        200: "#D1D3D6",
        100: "#F2F3F4",
    },
    positive: {
        100: "#EBFEDA",
        200: "#D1FDB7",
        300: "#B1F992",
        400: "#91F375",
        500: "#61EB49",
        600: "#3FCA35",
        700: "#24A925",
        800: "#178820",
        900: "#0E701E",
    },
    info: {
        100: "#E2F3FF",
        200: "#C5E5FF",
        300: "#A8D5FF",
        400: "#92C6FF",
        500: "#6EADFF",
        600: "#5086DB",
        700: "#3763B7",
        800: "#234593",
        900: "#152F7A",
    },
    notice: {
        100: "#FFEFCF",
        200: "#FFD99F",
        300: "#FFBF70",
        400: "#FFA54C",
        500: "#FF7B11",
        600: "#DB5D0C",
        700: "#B74308",
        800: "#932D05",
        900: "#7A1E03",
    },
    negative: {
        900: "#CC002C",
        800: "#E50032",
        700: "#FF0A3F",
        600: "#FF305D",
        500: "#FF577B",
        400: "#FF7D99",
        300: "#FFA3B7",
        200: "#FFC9D5",
        100: "#FFF0F3",
    },
    white: {
        100: "#FFFFFF",
    },
    black: {
        100: "#000000",
    },
    background: {
        100: "#F7F9FA",
        200: "#EAECED",
    },
} as const;
