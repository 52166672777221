import React, { useEffect, useRef, useState } from "react";

import { useRouter } from "next/navigation";

import { Close, Menu } from "@mui/icons-material";
import { Button, Collapse, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";

import { mobileMaxWidthMediaQuery, sixHundMaxWidthMediaQuery } from "@taghere/common-utils";

import { tmrColorPalette } from "@/consts/colors";
import { useThrottle } from "@/utils/useThrottle";

import { TAGHERE_TALLY_BUTTON } from "../../consts/ui";
import { TAGHERE_FRONT_HOST, TAGHERE_INFO_HOST } from "../../consts/url";

interface LandingHeaderSectionProps {
    color: "white" | "black";
    tallyLink: string;
    isShowing?: boolean;
}

const LandingHeaderSection: React.FC<LandingHeaderSectionProps> = ({
    color,
    tallyLink,
    isShowing = true,
}) => {
    const router = useRouter();
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);
    const headerBoxRef = useRef<HTMLDivElement>(null);
    const headerBoxMenuRef = useRef<HTMLDivElement>(null);
    const preScroll = useRef(0);
    const [isShowHeader, setIsShowHeader] = useState(true);

    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const handleMouseEnter = () => {
        setShowSubMenu(true);
    };

    const handleMouseLeave = () => {
        setShowSubMenu(false);
    };

    const makeTmrColor = (color: "white" | "black") => {
        if (color === "white") return tmrColorPalette.white[100];
        return tmrColorPalette.grey[900];
    };

    const scrollEvent = () => {
        const currentScroll = window.scrollY;
        if (preScroll.current < currentScroll) {
            setShowMenu(() => false);
            setIsShowHeader(() => false);
            if (currentScroll <= 50) {
                setIsShowHeader(() => true);
            }
        } else {
            setIsShowHeader(() => true);
        }
        preScroll.current = window.scrollY;
    };
    const throttledScroll = useThrottle(scrollEvent, 100);

    useEffect(() => {
        window.addEventListener("scroll", throttledScroll);
        return () => window.removeEventListener("scroll", throttledScroll);
    }, []);

    return (
        <Box
            onMouseLeave={handleMouseLeave}
            ref={headerBoxRef}
            sx={{
                position: "fixed",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                flexDirection: "row",
                height: "70px",
                width: "100vw",
                zIndex: "999",
                paddingX: "30px",
                background: showMenu ? "white" : "transparent",

                ...(is600 && {
                    transform: isShowHeader ? `translateY(0)` : `translateY(-100px)`,
                    transition: "transform 0.4s ease-in-out",
                }),

                ...(!showMenu &&
                    (!isShowing
                        ? {
                              transform: `translateY(-${headerBoxRef.current?.clientHeight}px)`,
                              transition: "transform 0.3s ease-in-out",
                          }
                        : {
                              transition: "transform 0.3s ease-in-out",
                          })),
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "row",
                    width: is600 ? "100%" : "1200px",
                }}
            >
                <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        router.push("/");
                    }}
                >
                    <img
                        src={`/assets/images/lp/logo_${showMenu ? "black" : color}.svg`}
                        alt=""
                    />
                </Box>
                {is600 ? (
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setShowMenu((prev) => !prev);
                            }}
                            sx={{
                                cursor: "pointer",
                                padding: 0,
                            }}
                        >
                            {showMenu ? (
                                <Close htmlColor={"black"} sx={{ fontSize: "24px" }} />
                            ) : (
                                <Menu
                                    htmlColor={showMenu ? "black" : makeTmrColor(color)}
                                    sx={{ fontSize: "24px" }}
                                />
                            )}
                        </IconButton>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "30px",
                        }}
                    >
                        <Box
                            onMouseEnter={handleMouseEnter}
                            sx={{
                                position: "relative",
                            }}
                        >
                            <Button
                                onMouseEnter={handleMouseEnter}
                                sx={{
                                    ":hover": {
                                        backgroundColor: tmrColorPalette.background[100],
                                    },
                                    borderRadius: "10px",
                                }}
                            >
                                <Typography
                                    variant="f14B"
                                    sx={{
                                        whiteSpace: "pre-line",
                                        color: makeTmrColor(color),
                                    }}
                                >
                                    서비스 소개
                                </Typography>
                            </Button>
                            {showSubMenu && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "calc(100% + 10px)",
                                        left: 0,
                                        backgroundColor: tmrColorPalette.background[100],
                                        padding: "10px",
                                        borderRadius: "10px",
                                        display: "flex",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Box
                                        onClick={() => {
                                            router.push("/");
                                        }}
                                        sx={{
                                            padding: "11px 15px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="f16SB"
                                            sx={{
                                                lineHeight: 1.3,
                                                color: tmrColorPalette.grey[500],
                                            }}
                                        >
                                            주문 서비스
                                        </Typography>
                                    </Box>
                                    <Box
                                        onClick={() => {
                                            router.push("/payment");
                                        }}
                                        sx={{
                                            padding: "11px 15px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="f16SB"
                                            sx={{
                                                lineHeight: 1.3,
                                                color: tmrColorPalette.grey[500],
                                            }}
                                        >
                                            결제 서비스
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Button
                            onClick={() => {
                                window?.open("https://docs.channel.io/0614/ko")?.focus();
                            }}
                        >
                            <Typography
                                variant="f14B"
                                sx={{
                                    whiteSpace: "pre-line",
                                    color: makeTmrColor(color),
                                }}
                            >
                                사용 사례
                            </Typography>
                        </Button>
                        <Button
                            onClick={() => {
                                router.push("/FAQ");
                            }}
                        >
                            <Typography
                                variant="f14B"
                                sx={{
                                    whiteSpace: "pre-line",
                                    color: makeTmrColor(color),
                                }}
                            >
                                자주 묻는 질문
                            </Typography>
                        </Button>
                        <Button
                            id={"goTallyButton"}
                            sx={{
                                display: "inline-flex",
                                padding: "16px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "80px",
                                backgroundColor: tmrColorPalette.primary[500],
                            }}
                            onClick={() => {
                                window?.open(tallyLink)?.focus();
                            }}
                        >
                            <Typography
                                id={"goTallyButton"}
                                variant="f14B"
                                sx={{
                                    whiteSpace: "pre-line",
                                    color:
                                        color === "white"
                                            ? makeTmrColor("white")
                                            : makeTmrColor("black"),
                                    width: "100%",
                                }}
                            >
                                {TAGHERE_TALLY_BUTTON}
                            </Typography>
                        </Button>
                    </Box>
                )}
                {is600 && showMenu && (
                    <Box
                        ref={headerBoxMenuRef}
                        sx={{
                            background: "#FFF",
                            position: "fixed",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "left",
                            alignItems: "left",
                            top: "70px",
                            left: 0,
                            width: "100vw",
                            paddingX: "30px",
                            paddingBottom: "92px",
                        }}
                    >
                        <Box
                            sx={{
                                minHeight: "54px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Button
                                sx={{ justifyContent: "left", height: "54px" }}
                                onClick={() => {
                                    setShowSubMenu((prev) => !prev);
                                }}
                            >
                                <Typography
                                    variant="f16SB"
                                    sx={{
                                        whiteSpace: "pre-line",
                                        color: tmrColorPalette.grey[900],
                                    }}
                                >
                                    서비스 소개
                                </Typography>
                            </Button>
                            <Collapse in={showSubMenu} timeout="auto" unmountOnExit>
                                <Box
                                    onMouseEnter={handleMouseEnter}
                                    sx={{
                                        backgroundColor: tmrColorPalette.background[100],
                                        borderRadius: "10px",
                                        display: "flex",
                                        whiteSpace: "nowrap",
                                        flexDirection: "column",
                                        textAlign: "left",
                                        gap: "15px",
                                        padding: "20px",
                                    }}
                                >
                                    <Box
                                        onClick={() => {
                                            router.push("/");
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="f16SB"
                                            sx={{
                                                lineHeight: 1.3,
                                                color: tmrColorPalette.grey[500],
                                            }}
                                        >
                                            주문 서비스
                                        </Typography>
                                    </Box>
                                    <Box
                                        onClick={() => {
                                            router.push("/payment");
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="f16SB"
                                            sx={{
                                                lineHeight: 1.3,
                                                color: tmrColorPalette.grey[500],
                                            }}
                                        >
                                            결제 서비스
                                        </Typography>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Box>
                        <Button
                            sx={{ justifyContent: "left", height: "54px" }}
                            onClick={() => {
                                window?.open("https://docs.channel.io/0614/ko")?.focus();
                            }}
                        >
                            <Typography
                                variant="f16SB"
                                sx={{
                                    whiteSpace: "pre-line",
                                    color: tmrColorPalette.grey[900],
                                }}
                            >
                                사용 사례
                            </Typography>
                        </Button>
                        <Button
                            sx={{ justifyContent: "left", height: "54px" }}
                            onClick={() => {
                                router.push("/FAQ");
                            }}
                        >
                            <Typography
                                variant="f16SB"
                                sx={{
                                    whiteSpace: "pre-line",
                                    color: tmrColorPalette.grey[900],
                                }}
                            >
                                자주 묻는 질문
                            </Typography>
                        </Button>

                        <Box
                            sx={{
                                display: "inline-flex",
                                justifyContent: "left",
                                alignItems: "left",
                                gap: "10px",
                            }}
                        >
                            <Button
                                id={"goTallyButton"}
                                sx={{
                                    display: "inline-flex",
                                    padding: "16px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "80px",
                                    backgroundColor: tmrColorPalette.primary[500],
                                    marginTop: "15px",
                                }}
                                target={"_blank"}
                                href={tallyLink}
                            >
                                <Typography
                                    id={"goTallyButton"}
                                    variant="f14B"
                                    sx={{
                                        whiteSpace: "pre-line",
                                        color: tmrColorPalette.grey[900],

                                        width: "100%",
                                    }}
                                >
                                    {TAGHERE_TALLY_BUTTON}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default LandingHeaderSection;
