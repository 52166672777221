import { datadogRum } from "@datadog/browser-rum";

if (
    !!process.env["NEXT_PUBLIC_DD_RUM_APPLICATION_ID"] &&
    !!process.env["NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN"] &&
    !!process.env["NEXT_PUBLIC_ENV"] &&
    ["prod", "qa"].includes(process.env["NEXT_PUBLIC_ENV"])
) {
    datadogRum.init({
        applicationId: process.env["NEXT_PUBLIC_DD_RUM_APPLICATION_ID"],
        clientToken: process.env["NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN"],
        site: "datadoghq.com",
        service: "taghere-info",
        env: process.env["NEXT_PUBLIC_ENV"],
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
    });
}
