import React from "react";

import { Close } from "@mui/icons-material";
import { Dialog, DialogProps, DialogTitle, IconButton, Typography } from "@mui/material";

import { FontWeightValues } from "@taghere/common-utils";

import { ForwardedSlideUpTransition } from "../modals";

interface BasicModalProps {
    open: boolean;
    onClose?: () => void;
    title?: string;
    p?: number | string;
    children?: React.ReactNode;
    titleForceCenterAlign?: boolean;
}

const BasicModal: React.FC<BasicModalProps & DialogProps> = ({
    open,
    onClose,
    title,
    p = 3,
    children,
    titleForceCenterAlign,
    ...dialogProps
}) => {
    return (
        <Dialog
            maxWidth={"xs"}
            {...dialogProps}
            open={open}
            onClose={onClose}
            scroll={"paper"}
            TransitionComponent={ForwardedSlideUpTransition}
            PaperProps={{
                ...dialogProps.PaperProps,
                sx: {
                    width: "100%",
                    height: "auto",
                    maxHeight: "800px",
                    borderRadius: "20px",
                    p: p,
                    ...dialogProps.PaperProps?.sx,
                },
            }}
        >
            {title && (
                <DialogTitle
                    sx={{ p: 0, pb: 1, backgroundColor: "transparent" }}
                    display={"flex"}
                    justifyContent={titleForceCenterAlign ? "center" : "space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: FontWeightValues.SEMI_BOLD,
                        }}
                    >
                        {title}
                    </Typography>
                    {onClose && (
                        <IconButton onClick={onClose} sx={{ mr: -1.5 }}>
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
            )}
            {children}
        </Dialog>
    );
};

export default BasicModal;
